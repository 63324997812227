import { DEFAULT_LANG } from "constants";
import i18next from "i18next";
import { mn_MN, en_EN } from "./locale";
console.log(DEFAULT_LANG);

const resources = {
  mn: {
    translation: mn_MN,
  },
  en: {
    translation: en_EN,
  },
};
i18next.init({
  debug: false,
  lng: DEFAULT_LANG ?? "mn",
  fallbackLng: DEFAULT_LANG ?? "mn",
  keySeparator: ".",
  resources,
  interpolation: {
    escapeValue: false,
  }, // React already does escaping
  missingKeyHandler: true,
  parseMissingKeyHandler: (key) => {
    const parts = key.split(".");
    return parts[parts.length - 1];
  },
});

export default i18next;
