import { all, fork } from "redux-saga/effects";

import system from "./system";
import user from "./user";

const sagas = [...user.sagas, ...system.sagas];

export default function* root() {
  yield all(sagas.map((saga) => fork(saga)));
}
