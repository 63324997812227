import { Row, Col, Image } from "antd";

import "./index.less";

const VectureStudioItemLeft = ({
  logo,
  image,
  title,
  content,
  author,
  authorPosition,
}) => (
  <Row gutter={48} align="middle" className="sc-venture-studio-item" data-aos="fade-up">
    <Col xs={0} sm={0} md={0} lg={12}>
      <Image
        className="sc-venture-studio-item-image"
        preview={false}
        src={image}
      />
    </Col>
    <Col xs={0} sm={0} md={0} lg={12} className="sc-venture-studio-item-body">
      <Row justify="start">
        <Image
          className="sc-venture-studio-item-logo"
          preview={false}
          src={logo}
        />
      </Row>
      <div className="sc-venture-studio-item-title">{title}</div>
      <p className="sc-venture-studio-item-content">{content}</p>
      <p className="sc-venture-studio-item-author">{author}</p>
      <p className="sc-venture-studio-item-author-position">{authorPosition}</p>
    </Col>
    <Col xs={24} sm={24} md={24} lg={0} className="sc-venture-studio-item-body">
      <Row justify="start">
        <Image
          className="sc-venture-studio-item-logo"
          preview={false}
          src={logo}
        />
      </Row>
      <div className="sc-venture-studio-item-title">{title}</div>
      <p className="sc-venture-studio-item-content">{content}</p>
    </Col>
    <Col
      xs={24}
      sm={24}
      md={24}
      lg={0}
      className="sc-venture-studio-item-image"
    >
      <Image preview={false} src={image} />
    </Col>
    <Col
      xs={24}
      sm={24}
      md={24}
      lg={0}
      className="sc-venture-studio-item-image"
    >
      <p className="sc-venture-studio-item-author">{author}</p>
      <p className="sc-venture-studio-item-author-position">{authorPosition}</p>
    </Col>
  </Row>
);

const VectureStudioItemRight = ({
  logo,
  image,
  title,
  content,
  author,
  authorPosition,
}) => (
  <Row gutter={48} align="middle" className="sc-venture-studio-item" data-aos="fade-up">
    <Col xs={0} sm={0} md={0} lg={12} className="sc-venture-studio-item-body">
      <Row justify="start">
        <Image
          className="sc-venture-studio-item-logo"
          preview={false}
          src={logo}
        />
      </Row>
      <div className="sc-venture-studio-item-title">{title}</div>
      <p className="sc-venture-studio-item-content">{content}</p>
      <p className="sc-venture-studio-item-author">{author}</p>
      <p className="sc-venture-studio-item-author-position">{authorPosition}</p>
    </Col>
    <Col xs={0} sm={0} md={0} lg={12} className="sc-venture-studio-item-image">
      <Image preview={false} src={image} />
    </Col>
    <Col xs={24} sm={24} md={24} lg={0} className="sc-venture-studio-item-body">
      <Row justify="start">
        <Image
          className="sc-venture-studio-item-logo"
          preview={false}
          src={logo}
        />
      </Row>
      <div className="sc-venture-studio-item-title">{title}</div>
      <p className="sc-venture-studio-item-content">{content}</p>
    </Col>
    <Col
      xs={24}
      sm={24}
      md={24}
      lg={0}
      className="sc-venture-studio-item-image"
    >
      <Image preview={false} src={image} />
    </Col>
    <Col
      xs={24}
      sm={24}
      md={24}
      lg={0}
      className="sc-venture-studio-item-image"
    >
      <p className="sc-venture-studio-item-author">{author}</p>
      <p className="sc-venture-studio-item-author-position">{authorPosition}</p>
    </Col>
  </Row>
);

export const VectureStudioItem = ({
  left,
  logo,
  image,
  title,
  content,
  author,
  authorPosition,
}) => (
  <>
    {left ? (
      <VectureStudioItemLeft
        logo={logo}
        image={image}
        title={title}
        content={content}
        author={author}
        authorPosition={authorPosition}
      />
    ) : (
      <VectureStudioItemRight
        logo={logo}
        image={image}
        title={title}
        content={content}
        author={author}
        authorPosition={authorPosition}
      />
    )}
  </>
);
