import { createAction } from "redux-actions";
import {
  JOIN_US,
  JOIN_US_SUCCESS,
  JOIN_US_FAILED,
} from "./actionTypes";

export const joinUs = createAction(JOIN_US, (value) => value);
export const joinUsSuccess = createAction(
  JOIN_US_SUCCESS,
  (value) => value
);
export const joinUsFailed = createAction(JOIN_US_FAILED, (value) => value);
