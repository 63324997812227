import { takeLatest, put, call } from "redux-saga/effects";

import * as actions from "./actions";
import { postJoinUsList } from "../../helpers/backend.helper";

export function* joinUs() {
  try {
    const response = yield call(postJoinUsList);
    yield put(actions.joinUsSuccess({ response }));
  } catch (error) {
    yield put(actions.joinUsFailed(error));
  }
}

export function* watchJoinUs() {
  yield takeLatest(actions.joinUs, joinUs);
}

const userSagas = [watchJoinUs];
export default userSagas;
