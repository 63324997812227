import { useEffect } from "react";
import { Row, Col, Image } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { systemActions } from "store/actions";
import { systemSelectors } from "store/selectors";
import { ENDPOINT } from "constants";

import "./index.less";

const Page = ({ lang, ventureStudio, getVentureStudio }) => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getVentureStudio();
  }, [getVentureStudio]);

  return (
    <Row>
      <Col span={24}>
        <Row align="middle" justify="center" className="sc-vsvs-h">
          <Col span={24} className="sc-pg-max-1440">
            <Row justify="center">
              <Col xs={20} sm={20} md={20} lg={18} xl={18} xxl={16}>
                <Row
                  justify="start"
                  className="sc-vsvs-h-st"
                  data-aos="fade-up"
                >
                  {ventureStudio[`${lang}SubTitle`]}
                </Row>
                <Row justify="start" className="sc-vsvs-h-t" data-aos="fade-up">
                  {ventureStudio[`${lang}Title`]}
                </Row>
                <Row justify="start" className="sc-vsvs-h-c" data-aos="fade-up">
                  {ventureStudio[`${lang}Content`]}
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={20}>
            <Row justify="center" className="sc-vsvs-h-bt-o" data-aos="fade-up">
              <Col
                xs={20}
                sm={20}
                md={20}
                lg={18}
                xl={18}
                xxl={16}
                className="sc-vsvs-h-bt"
              >
                {ventureStudio.headerImage && (
                  <Image
                    src={`${ENDPOINT}${ventureStudio.headerImage}`}
                    preview={false}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="center" className="sc-vsvs-dt">
          <Col span={24} className="sc-pg-max-1440">
            <Row justify="center">
              <Col xs={20} sm={20} md={20} lg={18} xl={18} xxl={16}>
                <Row className="sc-vsvs-dt-h">
                  <span className="sc-vsvs-dt-h-t">
                    {ventureStudio[`${lang}HeadLine`]}
                  </span>
                </Row>
              </Col>
            </Row>
            <Row justify="center">
              {(ventureStudio.ventureStudioAddtionals ?? []).map((item) => (
                <Col
                  xs={20}
                  sm={20}
                  md={20}
                  lg={18}
                  xl={18}
                  xxl={16}
                  className="sc-vsvs-dt-itm"
                  data-aos="fade-up"
                >
                  <Row justify="start" className="sc-vsvs-dt-itm-t">
                    {item[`${lang}Title`]}
                  </Row>
                  <Row className="sc-vsvs-dt-itm-c">
                    {item[`${lang}Content`]}
                  </Row>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Row justify="center" className="sc-vsvs-stps">
          <Col span={24} className="sc-pg-max-1440">
            <Row justify="center">
              <Col xs={20} sm={20} md={20} lg={18} xl={18} xxl={16}>
                <Row className="sc-vsvs-stps-h">
                  <span className="sc-vsvs-stps-h-t">
                    {ventureStudio[`${lang}VentureStudioStepsTitle`]}
                  </span>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24} className="sc-pg-max-1440">
            <Row justify="center">
              <Col xs={22} sm={22} md={20} lg={18} xl={18} xxl={16}>
                {(ventureStudio?.ventureStudioSteps ?? []).map(
                  (item, index) => (
                    <Row
                      key={`vs-${index}`}
                      data-aos="fade-up"
                      data-aos-duration="1500"
                      gutter={24}
                      align="middle"
                      justify="center"
                      className="sc-vsvs-stp"
                    >
                      <Col xs={20} sm={20} md={12} lg={12}>
                        <Row justify="center" className="sc-vsvs-stp-img">
                          <Image
                            src={`${ENDPOINT}${item.image}`}
                            preview={false}
                          />
                        </Row>
                      </Col>
                      <Col xs={18} sm={18} md={12} lg={12}>
                        <Row justify="start" className="sc-vsvs-stp-t">
                          {item[`${lang}Title`]}
                        </Row>
                        {item[`${lang}Items`].map((object, index) => (
                          <Row
                            key={`vs-o-${index}`}
                            justify="start"
                            className="sc-vsvs-stp-cpts"
                          >
                            - {object}
                          </Row>
                        ))}
                      </Col>
                    </Row>
                  )
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="sc-vsvs-strtups-srvs">
          <Col span={24} className="sc-pg-max-1440">
            <Row justify="center">
              <Col xs={20} sm={20} md={20} lg={18} xl={18} xxl={16}>
                <Row className="sc-vsvs-strtups-srvs-h">
                  <div className="sc-vsvs-strtups-srvs-h-t">
                    {ventureStudio[`${lang}VentureStudioServicesTitle`]}
                  </div>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24} className="sc-pg-max-1440">
            <Row justify="center">
              <Col xs={20} sm={20} md={20} lg={18} xl={18} xxl={16}>
                <Row
                  gutter={[
                    { xs: 0, sm: 40, md: 40, lg: 220, xl: 220, xxl: 220 },
                    42,
                  ]}
                  justify="center"
                  className="sc-vsvs-strtups-srvs-itms"
                >
                  {(ventureStudio.ventureStudioServices ?? []).map(
                    (item, index) => (
                      <Col
                        key={`srvs-${index}`}
                        xs={22}
                        sm={22}
                        md={16}
                        lg={10}
                        xl={10}
                        xxl={10}
                        className="sc-vsvs-strtups-srvs-itm"
                      >
                        <Row align="middle">
                          <Col
                            flex="40px"
                            className="sc-vsvs-strtups-srvs-itm-img"
                          >
                            <Image
                              preview={false}
                              src={`${ENDPOINT}${item.image}`}
                            />
                          </Col>
                          <Col
                            flex="auto"
                            className="sc-vsvs-strtups-srvs-itm-c"
                          >
                            {item[`${lang}Title`]}
                          </Col>
                        </Row>
                      </Col>
                    )
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="center" className="sc-vsvs-f-o">
          <Col span={24} className="sc-pg-max-1440">
            <Row justify="center">
              <Col
                xs={22}
                sm={22}
                md={19}
                lg={17}
                xl={17}
                xxl={15}
                className="sc-vsvs-f"
              >
                <Row className="sc-vsvs-f-st">{t("nextPage")}</Row>
                <Link to="/portfolio">
                  <Row className="sc-vsvs-f-t">
                    <span>{t("menu.portfolioStartups")}</span>
                    <Image
                      preview={false}
                      src={require("../../assets/images/left-arrow-red.png")}
                      className="sc-vsvs-f-t-arrow"
                    />
                  </Row>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  lang: systemSelectors.getLang(state),
  ventureStudio: systemSelectors.getVentureStudio(state),
});

const mapDispatchToProps = (dispatch) => ({
  getVentureStudio() {
    dispatch(systemActions.getVentureStudio());
  },
});

export const VentureStudio = connect(mapStateToProps, mapDispatchToProps)(Page);
