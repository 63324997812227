import AOS from "aos";
import { AppRoutes } from "structure";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";

import configureStore from "store";
import i18n from "i18n";
import { useEffect } from "react";

const store = configureStore();
store.runSaga();

window.store = store;

const ErrorHandler = () => <>Something wrong</>;

const App = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <ErrorBoundary FallbackComponent={ErrorHandler}>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </I18nextProvider>
      </Provider>
    </ErrorBoundary>
  );
};

export default App;
