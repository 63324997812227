export const NAME = `system`;

export const INIT = `init`;
export const CHANGE_LANGUAGE = `change_language`;
export const GET_HOME = `get_home`;
export const GET_VENTURE_STUDIO = `get_venture_studio`;
export const GET_PORTFOLIO = `get_portfolio`;
export const GET_EVENT = `get_event`;
export const GET_TRAINING = `get_training`;
export const GET_ABOUT = `get_about`;
export const GET_ECOSYSTEM_MAP = `get_eco_system_map`;
export const CONTACT_US = `get_contact_us`;
