import { DEFAULT_LANG } from "constants";
import { handleActions } from "redux-actions";
import * as actions from "./actions";
import * as constants from "./constants";

export const defaultState = {
  loader: {},
  errors: {},
  responses: {},
  lang: DEFAULT_LANG ?? "mn",
  home: {},
  ventureStudio: {},
  portfolio: {},
  event: {},
  training: {},
  about: {},
  ecoSystemMap: {},
};

export default handleActions(
  {
    [actions.init]: (state) => ({
      ...state,
      loader: {
        [constants.INIT]: true,
      },
    }),
    [actions.initSuccess]: (state, action) => ({
      ...state,
      loader: {
        [constants.INIT]: false,
      },
      responses: {
        [constants.INIT]: true,
      },
      ...action.payload,
    }),
    [actions.initFailed]: (state) => ({
      ...state,
      loader: {
        [constants.INIT]: false,
      },
      errors: {
        [constants.INIT]: true,
      },
    }),
    [actions.changeLanguage]: (state) => ({
      ...state,
      loader: {
        [constants.CHANGE_LANGUAGE]: true,
      },
    }),
    [actions.changeLanguageSuccess]: (state, action) => ({
      ...state,
      loader: {
        [constants.CHANGE_LANGUAGE]: false,
      },
      responses: {
        [constants.CHANGE_LANGUAGE]: true,
      },
      ...action.payload,
    }),
    [actions.changeLanguageFailed]: (state) => ({
      ...state,
      loader: {
        [constants.CHANGE_LANGUAGE]: false,
      },
      errors: {
        [constants.CHANGE_LANGUAGE]: true,
      },
    }),
    [actions.getHome]: (state) => ({
      ...state,
      loader: {
        [constants.GET_HOME]: true,
      },
    }),
    [actions.getHomeSuccess]: (state, action) => ({
      ...state,
      loader: {
        [constants.GET_HOME]: false,
      },
      responses: {
        [constants.GET_HOME]: true,
      },
      ...action.payload,
    }),
    [actions.getHomeFailed]: (state) => ({
      ...state,
      loader: {
        [constants.GET_HOME]: false,
      },
      errors: {
        [constants.GET_HOME]: true,
      },
    }),
    [actions.getVentureStudio]: (state) => ({
      ...state,
      loader: {
        [constants.GET_VENTURE_STUDIO]: true,
      },
    }),
    [actions.getVentureStudioSuccess]: (state, action) => ({
      ...state,
      loader: {
        [constants.GET_VENTURE_STUDIO]: false,
      },
      responses: {
        [constants.GET_VENTURE_STUDIO]: true,
      },
      ...action.payload,
    }),
    [actions.getVentureStudioFailed]: (state) => ({
      ...state,
      loader: {
        [constants.GET_VENTURE_STUDIO]: false,
      },
      errors: {
        [constants.GET_VENTURE_STUDIO]: true,
      },
    }),
    [actions.getPortfolio]: (state) => ({
      ...state,
      loader: {
        [constants.GET_PORTFOLIO]: true,
      },
    }),
    [actions.getPortfolioSuccess]: (state, action) => ({
      ...state,
      loader: {
        [constants.GET_PORTFOLIO]: false,
      },
      responses: {
        [constants.GET_PORTFOLIO]: true,
      },
      ...action.payload,
    }),
    [actions.getPortfolioFailed]: (state) => ({
      ...state,
      loader: {
        [constants.GET_PORTFOLIO]: false,
      },
      errors: {
        [constants.GET_PORTFOLIO]: true,
      },
    }),
    [actions.getEvent]: (state) => ({
      ...state,
      loader: {
        [constants.GET_EVENT]: true,
      },
    }),
    [actions.getEventSuccess]: (state, action) => ({
      ...state,
      loader: {
        [constants.GET_EVENT]: false,
      },
      responses: {
        [constants.GET_EVENT]: true,
      },
      ...action.payload,
    }),
    [actions.getEventFailed]: (state) => ({
      ...state,
      loader: {
        [constants.GET_EVENT]: false,
      },
      errors: {
        [constants.GET_EVENT]: true,
      },
    }),
    [actions.getTraining]: (state) => ({
      ...state,
      loader: {
        [constants.GET_TRAINING]: true,
      },
    }),
    [actions.getTrainingSuccess]: (state, action) => ({
      ...state,
      loader: {
        [constants.GET_TRAINING]: false,
      },
      responses: {
        [constants.GET_TRAINING]: true,
      },
      ...action.payload,
    }),
    [actions.getTrainingFailed]: (state) => ({
      ...state,
      loader: {
        [constants.GET_TRAINING]: false,
      },
      errors: {
        [constants.GET_TRAINING]: true,
      },
    }),
    [actions.getAbout]: (state) => ({
      ...state,
      loader: {
        [constants.GET_ABOUT]: true,
      },
    }),
    [actions.getAboutSuccess]: (state, action) => ({
      ...state,
      loader: {
        [constants.GET_ABOUT]: false,
      },
      responses: {
        [constants.GET_ABOUT]: true,
      },
      ...action.payload,
    }),
    [actions.getAboutFailed]: (state) => ({
      ...state,
      loader: {
        [constants.GET_ABOUT]: false,
      },
      errors: {
        [constants.GET_ABOUT]: true,
      },
    }),
    [actions.getEcoSystemMap]: (state) => ({
      ...state,
      loader: {
        [constants.GET_ECOSYSTEM_MAP]: true,
      },
    }),
    [actions.getEcoSystemMapSuccess]: (state, action) => ({
      ...state,
      loader: {
        [constants.GET_ECOSYSTEM_MAP]: false,
      },
      responses: {
        [constants.GET_ECOSYSTEM_MAP]: true,
      },
      ...action.payload,
    }),
    [actions.getEcoSystemMapFailed]: (state) => ({
      ...state,
      loader: {
        [constants.GET_ECOSYSTEM_MAP]: false,
      },
      errors: {
        [constants.GET_ECOSYSTEM_MAP]: true,
      },
    }),
    [actions.contactUs]: (state) => ({
      ...state,
      loader: {
        [constants.CONTACT_US]: true,
      },
      responses: {
        [constants.CONTACT_US]: false,
      },
      errors: {
        [constants.CONTACT_US]: false,
      },
    }),
    [actions.contactUsSuccess]: (state, action) => ({
      ...state,
      loader: {
        [constants.CONTACT_US]: false,
      },
      responses: {
        [constants.CONTACT_US]: true,
      },
      ...action.payload,
    }),
    [actions.contactUsFailed]: (state) => ({
      ...state,
      loader: {
        [constants.CONTACT_US]: false,
      },
      errors: {
        [constants.CONTACT_US]: true,
      },
    }),
  },
  defaultState
);
