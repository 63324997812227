import { useEffect } from "react";
import { Row, Col, Image } from "antd";

import "./index.less";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { systemSelectors } from "store/selectors";
import { systemActions } from "store/actions";
import { connect } from "react-redux";
import { ENDPOINT } from "constants";

const Page = ({ lang, event, getEvent }) => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getEvent();
  }, [getEvent]);

  return (
    <Row>
      <Col span={24} style={{ backgroundColor: "#001423" }}>
        <Row justify="center" className="sc-vnt-h">
          <Col span={24} className="sc-pg-max-1440">
            <Row justify="center">
              <Col xs={22} sm={22} md={20} lg={18} xl={18} xxl={16}>
                <Row gutter={48} align="middle" justify="center">
                  <Col xs={22} sm={22} md={12}>
                    <Row
                      justify="center"
                      className="sc-vnt-h-img"
                      data-aos="fade-up"
                    >
                      {event.headerImage && (
                        <Image
                          src={`${ENDPOINT}${event.headerImage}`}
                          preview={false}
                        />
                      )}
                    </Row>
                  </Col>
                  <Col xs={22} sm={22} md={12}>
                    <Row
                      justify="center"
                      className="sc-vnt-h-t"
                      data-aos="fade-up"
                    >
                      {event[`${lang}Title`]}
                    </Row>
                    <Row
                      justify="center"
                      className="sc-vnt-h-c"
                      data-aos="fade-up"
                    >
                      {event[`${lang}Content`]}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="center" className="sc-vnt-itms">
          <Col span={24} className="sc-pg-max-1440">
            <Row justify="center">
              <Col xs={22} sm={22} md={20} lg={18} xl={18} xxl={16}>
                {(event.events ?? []).map((item) => (
                  <Row
                    gutter={48}
                    justify="center"
                    align="middle"
                    className="sc-vnt-itm"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    <Col xs={22} sm={22} md={12}>
                      <Row justify="center">
                        <Image
                          src={`${ENDPOINT}${item.image}`}
                          preview={false}
                        />
                      </Row>
                    </Col>
                    <Col xs={22} sm={22} md={12}>
                      <Row justify="start" className="sc-vnt-itm-t">
                        {item[`${lang}Title`]}
                      </Row>
                      <Row justify="center" className="sc-vnt-itm-c">
                        {item[`${lang}Content`]}
                      </Row>
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="center" className="sc-vnt-f-o">
          <Col span={24} className="sc-pg-max-1440">
            <Row justify="center">
              <Col
                xs={22}
                sm={22}
                md={19}
                lg={17}
                xl={17}
                xxl={15}
                className="sc-vnt-f"
              >
                <Row className="sc-vnt-f-st">{t("menu.contact")}</Row>
                <Link to="/contact-event">
                  <Row className="sc-vnt-f-t">
                    <span>{t("getEvent")}</span>
                    <Image
                      preview={false}
                      src={require("../../assets/images/left-arrow-red.png")}
                      className="sc-vnt-f-t-arrow"
                    />
                  </Row>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  lang: systemSelectors.getLang(state),
  event: systemSelectors.getEvent(state),
});

const mapDispatchToProps = (dispatch) => ({
  getEvent() {
    dispatch(systemActions.getEvent());
  },
});

export const Event = connect(mapStateToProps, mapDispatchToProps)(Page);
