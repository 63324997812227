import { useEffect } from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import { Outlet } from "react-router-dom";

import { Header } from "./header";
import { Footer } from "./footer";

import { systemActions } from "store/actions";

import "./index.less";

const MainLayoutC = ({ init }) => {
  useEffect(() => {
    init();
  }, [init]);

  return (
    <div>
      <Layout>
        <Header />
        <Layout.Content>
          <Outlet />
        </Layout.Content>
        <Footer />
      </Layout>
    </div>
  );
};

export const mapDispatchToProps = (dispatch) => ({
  init() {
    dispatch(systemActions.init());
  },
});

export const MainLayout = connect(null, mapDispatchToProps)(MainLayoutC);
