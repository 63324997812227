import { combineReducers } from 'redux'

import system from './system'
import user from './user'

const createRootReducer = (history) =>
  combineReducers({
    [system.constants.NAME]: system.reducer,
    [user.constants.NAME]: user.reducer,
  })
export default createRootReducer
