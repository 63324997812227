import { Row, Col, Image } from "antd";

import "./index.less";

export const FoundItem = ({
  image,
  content,
  author,
  authorPosition,
  dark = false,
}) => (
  <Row gutter={48} className={`sc-fi ${dark && "sc-fi-dark"}`}>
    <Col xs={24} sm={24} md={24} lg={24}>
      <Image
        fallback={require("../../assets/images/founder_image_fault_tolerant.png")}
        className="sc-fi-img"
        preview={false}
        src={image}
      />
      <p className="sc-fi-c">{content}</p>
      <p className="sc-fi-a">{author}</p>
      <p className="sc-fi-ap">{authorPosition}</p>
    </Col>
  </Row>
);
