import { Row, Col, Image, Tabs } from "antd";
import { FoundItem } from "components";
import { MemberTypes } from "constants";
import { ENDPOINT } from "constants";
import { useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { systemActions } from "store/actions";
import { systemSelectors } from "store/selectors";

import "./index.less";

const Page = ({ lang, about, getAbout }) => {
  const { t } = useTranslation();
  const [slickShowItems, setSlickShowItems] = useState(1);
  const [memberTabPosition, setMemberTabPosition] = useState("left");

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getAbout();
  }, [getAbout]);

  const updateWindowConfig = () => {
    if (window.innerWidth <= 1200) {
      setSlickShowItems(1);
      setMemberTabPosition("top");
    } else if (window.innerWidth <= 1440) {
      setSlickShowItems(2);
      setMemberTabPosition("top");
    } else {
      setSlickShowItems(3);
      setMemberTabPosition("left");
    }
  };

  useLayoutEffect(() => {
    updateWindowConfig();
    window.addEventListener("resize", updateWindowConfig);
    return () => window.removeEventListener("resize", updateWindowConfig);
  }, []);

  return (
    <Row>
      <Col span={24}>
        <Row align="bottom" justify="center" className="sc-abt-h-o">
          <Col span={24} className="sc-abt-h">
            <Row justify="center">
              <Col span={24} className="sc-pg-max-1440">
                <Row justify="center">
                  <Col xs={22} sm={22} md={20} lg={18} xl={18} xxl={16}>
                    <Row className="sc-abt-h-st" data-aos="fade-up">
                      {about[`${lang}SubTitle`]}
                    </Row>
                    <Row className="sc-abt-h-t" data-aos="fade-up">
                      {about[`${lang}Title`]}
                    </Row>
                    <Row
                      justify="center"
                      className="sc-abt-h-bt-o"
                      data-aos="fade-up"
                    >
                      <Col span={24} className="sc-abt-h-bt">
                        {about.headerImage && (
                          <Image
                            src={`${ENDPOINT}${about.headerImage}`}
                            preview={false}
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="sc-abt-ovrw">
          <Col span={24} className="sc-pg-max-1440">
            <Row justify="center">
              <Col span={16}>
                <Row className="sc-abt-ovrw-t">
                  {about[`${lang}StartTitle`]}
                </Row>
                <Row className="sc-abt-ovrw-c">
                  {about[`${lang}StartContent`]}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="sc-abt-htww">
          <Col span={24} className="sc-pg-max-1440">
            <Row justify="center">
              <Col xs={20} sm={20} md={16} lg={16} xl={20} xxl={18}>
                <div className="sc-abt-htww-tpd">
                  <Image
                    preview={false}
                    src={require("../../assets/images/about-htww-tpd.png")}
                  />
                </div>
                <Row className="sc-abt-htww-t">
                  {about[`${lang}KnowHowTitle`]}
                </Row>
                <Row className="sc-abt-htww-itms" gutter={[41, 41]}>
                  {(about.knowHowItems ?? []).map((item, index) => (
                    <Col
                      key={`know-how-${index}`}
                      xs={22}
                      sm={22}
                      md={22}
                      lg={8}
                      className="sc-abt-htww-itm"
                    >
                      <Row justify="center">
                        <Image
                          preview={false}
                          src={`${ENDPOINT}${item.image}`}
                        />
                      </Row>
                      <Row className="sc-abt-htww-itm-t">
                        {item[`${lang}Title`]}
                      </Row>
                      <Row className="sc-abt-htww-itm-c">
                        {item[`${lang}Content`]}
                      </Row>
                    </Col>
                  ))}
                </Row>
                <div className="sc-abt-htww-btd">
                  <Image
                    preview={false}
                    src={require("../../assets/images/about-htww-btd.png")}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* <Row className="sc-abt-our-cltr">
          <Col span={24} className="sc-pg-max-1440">
            <Row justify="center">
              <Col xs={20} sm={20} md={16} lg={16} xl={20} xxl={18}>
                <Row justify="start">
                  <Col xs={20} sm={20} md={20}>
                    <Row className="sc-abt-our-cltr-st">
                      {about[`${lang}FounderCommentSubTitle`]}
                    </Row>
                    <Row className="sc-abt-our-cltr-t">
                      {about[`${lang}FounderCommentTitle`]}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24} className="sc-pg-max-1440">
            <Row justify="center">
              <Col xs={22} sm={22} md={20} lg={21} xl={21} xxl={16}>
                <Slider
                  className="sc-slider"
                  slidesToShow={slickShowItems}
                  swipeToSlide={1}
                  focusOnSelect
                  variableWidth
                  arrows
                  infinite={false}
                  prevArrow={
                    <button>
                      <Image
                        src={require("../../assets/images/slider-prev-arrow-dark.png")}
                        preview={false}
                      />
                    </button>
                  }
                  nextArrow={
                    <button>
                      <Image
                        src={require("../../assets/images/slider-next-arrow-dark.png")}
                        preview={false}
                      />
                    </button>
                  }
                >
                  {(about.founderComments ?? []).map((item, index) => (
                    <FoundItem
                      dark
                      key={`fi-${index}`}
                      image={`${ENDPOINT}${item.image}`}
                      content={item[`${lang}Content`]}
                      author={item[`${lang}Author`]}
                    />
                  ))}
                </Slider>
              </Col>
            </Row>
          </Col>
        </Row> */}
        <Row className="sc-abt-tms">
          <Col span={24} className="sc-pg-max-1440">
            <Row justify="center">
              <Col span={16} offset={2}>
                <Row className="sc-abt-tms-t">
                  {about[`${lang}MemberTitle`]}
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24} className="sc-pg-max-1440">
            <Row justify="center">
              <Col xs={22} sm={22} md={20}>
                <Row justify="center">
                  <Tabs tabPosition={memberTabPosition ?? "left"}>
                    {MemberTypes.map(({ key }) => (
                      <Tabs.TabPane tab={t(`memberTypes.${key}`)} key={key}>
                        <Row
                          justify="start"
                          gutter={[
                            { xs: 220, sm: 220, md: 40, lg: 80, xl: 220 },
                            48,
                          ]}
                        >
                          {(about.members ?? [])
                            .filter((item) => item.roles.includes(key))
                            .map((item, index) => (
                              <Col
                                key={`about-member-${index}`}
                                xs={22}
                                sm={22}
                                md={12}
                                lg={8}
                                xl={8}
                                xxl={6}
                              >
                                <Row
                                  justify="center"
                                  className="sc-abt-tms-itm-img"
                                >
                                  <Image
                                    preview={false}
                                    src={`${ENDPOINT}${item.image}`}
                                  />
                                </Row>
                                <Row className="sc-abt-tms-itm-t">
                                  {item[`${lang}Name`]}
                                </Row>
                                <Row className="sc-abt-tms-itm-p">
                                  {item[`${lang}Position`]}
                                </Row>
                              </Col>
                            ))}
                        </Row>
                      </Tabs.TabPane>
                    ))}
                  </Tabs>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="center" className="sc-abt-f-o">
          <Col span={24} className="sc-pg-max-1440">
            <Row justify="center">
              <Col
                xs={22}
                sm={22}
                md={19}
                lg={17}
                xl={17}
                xxl={15}
                className="sc-abt-f"
              >
                <Row className="sc-abt-f-st">{t("nextPage")}</Row>
                <Link to="/training">
                  <Row className="sc-abt-f-t">
                    <span>{t("menu.training")}</span>
                    <Image
                      preview={false}
                      src={require("../../assets/images/left-arrow-red.png")}
                      className="sc-abt-f-t-arrow"
                    />
                  </Row>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  lang: systemSelectors.getLang(state),
  about: systemSelectors.getAbout(state),
});

const mapDispatchToProps = (dispatch) => ({
  getAbout() {
    dispatch(systemActions.getAbout());
  },
});

export const About = connect(mapStateToProps, mapDispatchToProps)(Page);
