export const ENDPOINT = process.env.REACT_APP_ENDPOINT;
export const DEFAULT_LANG = process.env.REACT_APP_DEFAULT_LANG;
export const GOOGLE_RECAPTCHA_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY;

export const MemberTypes = [
  {
    key: "main",
  },
  // {
  //   key: "boardOfDirector",
  // },
  // {
  //   key: "advisor",
  // },
];
