import { createSelector } from "reselect";
import * as constants from "./constants";

export const getState = (state) => state[constants.NAME];

export const getLang = createSelector(getState, (state) => state.lang);
export const getHome = createSelector(getState, (state) => state.home);
export const getVentureStudio = createSelector(
  getState,
  (state) => state.ventureStudio
);
export const getPortfolio = createSelector(
  getState,
  (state) => state.portfolio
);
export const getEvent = createSelector(getState, (state) => state.event);
export const getTraining = createSelector(getState, (state) => state.training);
export const getAbout = createSelector(getState, (state) => state.about);
export const getEcoSystemMap = createSelector(
  getState,
  (state) => state.ecoSystemMap
);

// Loading
export const getInitLoading = createSelector(
  getState,
  (state) => state.loader[constants.INIT]
);
export const getChangeLanguageLoading = createSelector(
  getState,
  (state) => state.loader[constants.CHANGE_LANGUAGE]
);
export const getHomeLoading = createSelector(
  getState,
  (state) => state.loader[constants.GET_HOME]
);
export const getContactUsLoading = createSelector(
  getState,
  (state) => state.loader[constants.CONTACT_US]
);

// Response
export const getInitResponse = createSelector(
  getState,
  (state) => state.responses[constants.INIT]
);
export const getChangeLanguageResponse = createSelector(
  getState,
  (state) => state.responses[constants.CHANGE_LANGUAGE]
);
export const getContactUsResponse = createSelector(
  getState,
  (state) => state.responses[constants.CONTACT_US]
);

// Error
export const getInitError = createSelector(
  getState,
  (state) => state.errors[constants.INIT]
);
export const getChangeLanguageError = createSelector(
  getState,
  (state) => state.errors[constants.CHANGE_LANGUAGE]
);
export const getContactUsError = createSelector(
  getState,
  (state) => state.errors[constants.CONTACT_US]
);
