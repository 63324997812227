import { createAction } from "redux-actions";
import {
  INIT,
  INIT_SUCCESS,
  INIT_FAILED,
  CHANGE_LANGUAGE,
  CHANGE_LANGUAGE_SUCCESS,
  CHANGE_LANGUAGE_FAILED,
  GET_HOME,
  GET_HOME_SUCCESS,
  GET_HOME_FAILED,
  GET_VENTURE_STUDIO,
  GET_VENTURE_STUDIO_SUCCESS,
  GET_VENTURE_STUDIO_FAILED,
  GET_PORTFOLIO,
  GET_PORTFOLIO_FAILED,
  GET_EVENT,
  GET_EVENT_SUCCESS,
  GET_EVENT_FAILED,
  GET_PORTFOLIO_SUCCESS,
  GET_TRAINING,
  GET_TRAINING_SUCCESS,
  GET_TRAINING_FAILED,
  GET_ABOUT,
  GET_ABOUT_SUCCESS,
  GET_ABOUT_FAILED,
  GET_ECOSYSTEM_MAP,
  GET_ECOSYSTEM_MAP_SUCCESS,
  GET_ECOSYSTEM_MAP_FAILED,
  CONTACT_US,
  CONTACT_US_SUCCESS,
  CONTACT_US_FAILED,
} from "./actionTypes";

export const init = createAction(INIT, (value) => value);
export const initSuccess = createAction(INIT_SUCCESS, (value) => value);
export const initFailed = createAction(INIT_FAILED, (value) => value);

export const changeLanguage = createAction(CHANGE_LANGUAGE, (value) => value);
export const changeLanguageSuccess = createAction(
  CHANGE_LANGUAGE_SUCCESS,
  (value) => value
);
export const changeLanguageFailed = createAction(
  CHANGE_LANGUAGE_FAILED,
  (value) => value
);

export const getHome = createAction(GET_HOME, (value) => value);
export const getHomeSuccess = createAction(GET_HOME_SUCCESS, (value) => value);
export const getHomeFailed = createAction(GET_HOME_FAILED, (value) => value);

export const getVentureStudio = createAction(
  GET_VENTURE_STUDIO,
  (value) => value
);
export const getVentureStudioSuccess = createAction(
  GET_VENTURE_STUDIO_SUCCESS,
  (value) => value
);
export const getVentureStudioFailed = createAction(
  GET_VENTURE_STUDIO_FAILED,
  (value) => value
);

export const getPortfolio = createAction(GET_PORTFOLIO, (value) => value);
export const getPortfolioSuccess = createAction(
  GET_PORTFOLIO_SUCCESS,
  (value) => value
);
export const getPortfolioFailed = createAction(
  GET_PORTFOLIO_FAILED,
  (value) => value
);

export const getEvent = createAction(GET_EVENT, (value) => value);
export const getEventSuccess = createAction(
  GET_EVENT_SUCCESS,
  (value) => value
);
export const getEventFailed = createAction(GET_EVENT_FAILED, (value) => value);

export const getTraining = createAction(GET_TRAINING, (value) => value);
export const getTrainingSuccess = createAction(
  GET_TRAINING_SUCCESS,
  (value) => value
);
export const getTrainingFailed = createAction(
  GET_TRAINING_FAILED,
  (value) => value
);

export const getAbout = createAction(GET_ABOUT, (value) => value);
export const getAboutSuccess = createAction(
  GET_ABOUT_SUCCESS,
  (value) => value
);
export const getAboutFailed = createAction(GET_ABOUT_FAILED, (value) => value);

export const getEcoSystemMap = createAction(
  GET_ECOSYSTEM_MAP,
  (value) => value
);
export const getEcoSystemMapSuccess = createAction(
  GET_ECOSYSTEM_MAP_SUCCESS,
  (value) => value
);
export const getEcoSystemMapFailed = createAction(
  GET_ECOSYSTEM_MAP_FAILED,
  (value) => value
);

export const contactUs = createAction(CONTACT_US, (value) => value);
export const contactUsSuccess = createAction(
  CONTACT_US_SUCCESS,
  (value) => value
);
export const contactUsFailed = createAction(
  CONTACT_US_FAILED,
  (value) => value
);
