import sagas from "./sagas";
import reducer from "./reducer";

import * as actions from "./actions";
import * as types from "./actionTypes";
import * as constants from "./constants";
import * as selectors from "./selectors";

window.reducer = reducer;

const user = {
  sagas,
  types,
  actions,
  reducer,
  selectors,
  constants,
}

export default user;
