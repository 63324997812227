import { handleActions } from 'redux-actions'
import * as actions from './actions'
import * as constants from './constants'

export const defaultState = {
  loader: {},
  errors: {},
  responses: {},
}

export default handleActions(
  {
    [actions.joinUs]: (state) => ({
      ...state,
      loader: {
        [constants.JOIN_US]: true,
      },
    }),
    [actions.joinUsSuccess]: (state, action) => ({
      ...state,
      loader: {
        [constants.JOIN_US]: false,
      },
      responses: {
        [constants.JOIN_US]: true,
      },
      ...action.payload,
    }),
    [actions.joinUsFailed]: (state) => ({
      ...state,
      loader: {
        [constants.JOIN_US]: false,
      },
      errors: {
        [constants.JOIN_US]: true,
      },
    }),
  },
  defaultState
)
