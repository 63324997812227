import { useEffect } from "react";
import { Row, Col, Image } from "antd";
import { VectureStudioItem } from "components/vecture-studio-item";

import "./index.less";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { systemSelectors } from "store/selectors";
import { systemActions } from "store/actions";
import { ENDPOINT } from "constants";

const Page = ({ lang, portfolio, getPortfolio }) => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getPortfolio();
  }, [getPortfolio]);

  return (
    <Row>
      <Col span={24}>
        <Row justify="center" className="sc-pfo-h">
          <Col span={24} className="sc-pg-max-1440">
            <Row justify="center">
              <Col xs={22} sm={22} md={20} lg={18} xl={18} xxl={16}>
                <Row justify="start" className="sc-pfo-h-st" data-aos="fade-up">
                  {portfolio[`${lang}SubTitle`]}
                </Row>
                <Row justify="start" className="sc-pfo-h-t" data-aos="fade-up">
                  {portfolio[`${lang}Title`]}
                </Row>
                <Row justify="start" className="sc-pfo-h-c" data-aos="fade-up">
                  {portfolio[`${lang}Content`]}
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={18}>
            <Row justify="center" className="sc-pfo-h-bt-o" data-aos="fade-up">
              <Col span={24} className="sc-pfo-h-bt">
                {portfolio.headerImage && (
                  <Image
                    src={`${ENDPOINT}${portfolio.headerImage}`}
                    preview={false}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="center" className="sc-pfo-vs">
          <Col span={24} className="sc-pg-max-1440">
            <Row justify="center">
              <Col xs={22} sm={22} md={20} lg={18} xl={18} xxl={16}>
                {(portfolio.founderReviews ?? []).map((item, index) => (
                  <VectureStudioItem
                    key={`vsi-${index}`}
                    left={index === 0 || index % 2 === 0}
                    logo={`${ENDPOINT}${item.logo}`}
                    image={`${ENDPOINT}${item.image}`}
                    title={t("founderItem.title", {
                      year: item.releasedDate,
                      sector: item[`${lang}Sector`],
                    })}
                    content={item[`${lang}Content`]}
                    author={item[`${lang}Author`]}
                    authorPosition={item[`${lang}AuthorPosition`]}
                  />
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="center" style={{ backgroundColor: "#001423" }}>
          <Col span={24}>
            <Row className="sc-pfo-strtps" justify="center">
              <Col span={24} className="sc-pg-max-1440">
                <Row justify="center">
                  <Col xs={22} sm={22} md={20} lg={18} xl={18} xxl={16}>
                    <Row
                      justify="center"
                      className="sc-pfo-strtps-h"
                      data-aos="fade-up"
                    >
                      <span className="sc-pfo-strtps-h-t">
                        {portfolio[`${lang}StartupsTitle`]}
                      </span>
                    </Row>
                    <Row
                      justify="start"
                      align="middle"
                      className="sc-pfo-strtps-itms"
                      data-aos="fade-up"
                      gutter={30}
                    >
                      {(portfolio?.startups ?? []).map((item, index) => (
                        <Col
                          key={`strtps-${index}`}
                          xs={22}
                          sm={22}
                          md={12}
                          lg={8}
                          xl={8}
                          xxl={6}
                          className="sc-pfo-strtps-itm-o"
                        >
                          <a
                            href={`${item.link}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div className="sc-pfo-strtps-itm">
                              <Row
                                align="middle"
                                justify="center"
                                className="sc-pfo-strtps-itm-lg"
                              >
                                <Image
                                  src={`${ENDPOINT}${item.logo}`}
                                  preview={false}
                                />
                              </Row>
                              <Row
                                justify="center"
                                align="middle"
                                className="sc-pfo-strtps-itm-c"
                              >
                                {item[`${lang}Content`]}
                              </Row>
                            </div>
                          </a>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="center" className="sc-pfo-f-o">
          <Col span={24} className="sc-pg-max-1440">
            <Row justify="center">
              <Col
                xs={22}
                sm={22}
                md={20}
                lg={17}
                xl={17}
                xxl={15}
                className="sc-pfo-f"
              >
                <Row className="sc-pfo-f-st">{t("menu.contact")}</Row>
                <Link to="/contact-us">
                  <Row className="sc-pfo-f-t">
                    <span>{t("getPortfolio")}</span>
                    <Image
                      preview={false}
                      src={require("../../assets/images/left-arrow-red.png")}
                      className="sc-pfo-f-t-arrow"
                    />
                  </Row>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  lang: systemSelectors.getLang(state),
  portfolio: systemSelectors.getPortfolio(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPortfolio() {
    dispatch(systemActions.getPortfolio());
  },
});

export const Portfolio = connect(mapStateToProps, mapDispatchToProps)(Page);
