import { useEffect } from "react";
import { Row, Col, Image } from "antd";
import Slider from "react-slick";

import "./index.less";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { systemSelectors } from "store/selectors";
import { systemActions } from "store/actions";
import { connect } from "react-redux";
import { ENDPOINT } from "constants";

const Page = ({ lang, training, getTraining }) => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getTraining();
  }, [getTraining]);

  return (
    <Row>
      <Col span={24}>
        <Row justify="center" className="sc-trnng-h">
          <Col span={24} className="sc-pg-max-1440">
            <Row justify="center">
              <Col span={22}>
                <Row justify="center">
                  <Col xs={22} sm={22} md={20} data-aos="fade-up">
                    <Slider
                      // autoplay
                      className="sc-trnng-sldr"
                      dots={false}
                      infinite={true}
                      // speed={500}
                      slidesToShow={1}
                      slidesToScroll={1}
                    >
                      {(training.slides ?? []).map((item, index) => (
                        <div className="sc-trnng-sldr-itm">
                          <Image
                            className="sc-trnng-sldr-itm-img"
                            src={`${ENDPOINT}${item}`}
                            preview={false}
                          />
                        </div>
                      ))}
                    </Slider>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="center" className="sc-trnng-info">
          <Col span={24} className="sc-pg-max-1440">
            <Row justify="center">
              <Col xs={22} sm={22} md={16} data-aos="fade-up">
                <Row justify="start" className="sc-trnng-info-t">
                  {training[`${lang}Title`]}
                </Row>
                <Row justify="start" className="sc-trnng-info-c">
                  {training[`${lang}Content`]}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ backgroundColor: "black" }}>
          <Col span={24}>
            <Row justify="center" className="sc-trnng-prtnrs">
              <Col span={24} className="sc-pg-max-1440">
                <Row justify="center">
                  <Col span={20}>
                    <Row justify="center" className="sc-trnng-prtnrs-t">
                      {training[`${lang}PartnerTitle`]}
                    </Row>
                  </Col>
                </Row>
                <Row justify="center">
                  <Col className="sc-trnng-prtnrs-itms">
                    <Row justify="start" align="middle" gutter={[60, 60]}>
                      {(training.partners ?? []).map((item, index) => (
                        <Col
                          flex="none"
                          key={`partner-${index}`}
                          className="sc-trnng-prtnrs-itm"
                        >
                          <Image
                            src={`${ENDPOINT}${item.logo}`}
                            preview={false}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row justify="center" className="sc-trnng-intro">
              <Col span={24} className="sc-pg-max-1440">
                <Row justify="center">
                  <Col span={20}>
                    <Row justify="center" className="sc-trnng-intro-t">
                      {training[`${lang}SummaryTitle`]}
                    </Row>
                    <Row justify="center" align="top">
                      <Col xs={22} sm={22} md={20} lg={20}>
                        {(training.summaryItems ?? []).map((item, index) => (
                          <Row justify={index % 2 !== 0 ? "start" : "end"}>
                            <Col
                              key={`summary-item-${index}`}
                              xs={24}
                              sm={24}
                              md={12}
                              // data-aos="fade-right"
                              // data-aos-duration="3000"
                            >
                              <Row
                                align="bottom"
                                className="sc-trnng-intro-crs"
                              >
                                <Col span={24}>
                                  <Row>
                                    <Col span={24}>
                                      <div className="sc-trnng-intro-crs-img">
                                        <Image
                                          preview={false}
                                          src={require("../../assets/images/training-intro-course-header.png")}
                                        />
                                      </div>
                                      <div className="sc-trnng-intro-crs-t">
                                        {item[`${lang}Title`]}
                                      </div>
                                    </Col>
                                    <Col span={24}>
                                      <p className="sc-trnng-intro-crs-c">
                                        {item[`${lang}Content`]}
                                      </p>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        ))}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="center" className="sc-trnng-prcs">
          <Col span={24} className="sc-pg-max-1440">
            <Row justify="center">
              <Col xs={20} sm={20} md={20} lg={20} xl={22} xxl={24}>
                <Row className="sc-trnng-prcs-t">
                  {training[`${lang}ProcessTitle`]}
                </Row>
                <Row
                  justify="center"
                  className="sc-trnng-prcs-stps"
                  gutter={60}
                >
                  {(training[`${lang}ProcessItems`] ?? []).map(
                    (item, index) => (
                      <Col
                        key={`process-item-${index}`}
                        className="sc-trnng-prcs-stp"
                      >
                        <Row className="sc-trnng-prcs-stp-c">{item}</Row>
                      </Col>
                    )
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="sc-trnng-prcs-banner">
          <Image
            preview={false}
            src={require("../../assets/images/training-process-banner.png")}
          />
        </Row>
        <Row className="sc-trnng-prcs-lsns">
          <Col span={24} className="sc-pg-max-1440">
            <Row justify="center">
              <Col span={20}>
                <Row className="sc-trnng-prcs-lsns-t">
                  {training[`${lang}LessonTitle`]}
                </Row>
                <Row justify="space-around" gutter={[64, 64]}>
                  {(training[`${lang}LessonItems`] ?? []).map((item, index) => (
                    <Col
                      key={`lesson-item-${index}`}
                      flex="none"
                      className="sc-trnng-prcs-lsn"
                    >
                      <Row className="sc-trnng-prcs-lsn-c">{item}</Row>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24} className="sc-pg-max-1440">
            <Row justify="center">
              <Col xs={22} sm={22} md={20}>
                <div className="sc-trnng-prcs-grid">
                  {(training[`trainingGridItems`] ?? []).map((item, index) => (
                    <div
                      key={`grid-item-${index}`}
                      className={`sc-trnng-prcs-grid-item-${index}`}
                    >
                      <Image src={`${ENDPOINT}${item}`} />
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="center" className="sc-trnng-f-o">
          <Col span={24} className="sc-pg-max-1440">
            <Row justify="center">
              <Col
                xs={22}
                sm={22}
                md={20}
                lg={17}
                xl={17}
                xxl={15}
                className="sc-trnng-f"
              >
                <Row className="sc-trnng-f-st">{t("menu.contact")}</Row>
                <Link to="/contact-training">
                  <Row className="sc-trnng-f-t">
                    <span>{t("getTraining")}</span>
                    <Image
                      preview={false}
                      src={require("../../assets/images/left-arrow-red.png")}
                      className="sc-trnng-f-t-arrow"
                    />
                  </Row>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  lang: systemSelectors.getLang(state),
  training: systemSelectors.getTraining(state),
});

const mapDispatchToProps = (dispatch) => ({
  getTraining() {
    dispatch(systemActions.getTraining());
  },
});

export const Training = connect(mapStateToProps, mapDispatchToProps)(Page);
