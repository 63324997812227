/* eslint-disable jsx-a11y/anchor-is-valid */
import { Layout, Row, Col, Image, Menu } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import "./index.less";

export const Footer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onClick = (e) => {
    navigate(`/${e.key}`);
  };

  return (
    <Layout.Footer className="sc-f-o">
      <Row justify="center" align="middle" className="sc-f">
        <Col span={24} className="sc-pg-max-1440">
          <Row justify="center">
            <Col xs={22} sm={22} md={22} lg={22} xl={22} xxl={18}>
              <Row justify="center">
                <Col xs={22} sm={22} md={12}>
                  <Row align="start" justify="start">
                    <Col flex="none">
                      <div className="sc-f-m-lbl">{t("menu.home")}</div>
                    </Col>
                    <Col flex="auto">
                      <Menu theme="dark" mode="vertical" onClick={onClick}>
                        <Menu.Item key="venture-studio">
                          {t("menu.ventureStudio")}
                        </Menu.Item>
                        <Menu.Item key="portfolio">
                          {t("menu.portfolioStartups")}
                        </Menu.Item>
                        <Menu.Item key="about">{t("menu.about")}</Menu.Item>
                        <Menu.Item key="training">
                          {t("menu.training")}
                        </Menu.Item>
                        <Menu.Item key="event">{t("menu.event")}</Menu.Item>
                        <Menu.Item key="contact-us">
                          {t("menu.contactUs")}
                        </Menu.Item>
                      </Menu>
                    </Col>
                  </Row>
                </Col>
                <Col xs={22} sm={22} md={12}>
                  <Row align="middle" justify="end">
                    <div className="sc-f-ecsstm">
                      <a href="/mongolian-startup-eco-system-map">
                        <Row justify="center" className="sc-f-ecsstm-c">
                          {t("footer.mongolianStartupEcosystemMap")}
                        </Row>
                        <Row justify="center" className="sc-f-ecsstm-a">
                          <Image
                            src={require("../../../assets/images/home-left-arrow-red.png")}
                            preview={false}
                          />
                        </Row>
                      </a>
                    </div>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24} className="sc-pg-max-1440">
          <Row justify="center">
            <Col xs={22} sm={22} md={22} lg={22} xl={22} xxl={18}>
              <Row justify="center" className="sc-f-nf">
                <Col xs={0} sm={0} md={0} lg={0} xl={14} xxl={14}>
                  <Row gutter={[30, 10]} className="sc-f-nf-itms">
                    <Col flex="none" className="sc-f-nf-itm">
                      <a
                        href="mailto:info@startco.net"
                        target="_blank"
                        rel="noreferrer"
                      >
                        info@startco.net
                      </a>
                    </Col>
                    <Col flex="none" className="sc-f-nf-itm">
                      <a
                        href="tel:+976-80002662"
                        target="_blank"
                        rel="noreferrer"
                      >
                        +976-80002662
                      </a>
                    </Col>
                    <Col flex="none" className="sc-f-nf-itm">
                      <a
                        href="https://www.facebook.com/startcompanymn"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Facebook
                      </a>
                    </Col>
                    <Col flex="none" className="sc-f-nf-itm">
                      <a
                        href="https://www.linkedin.com/company/startinternational"
                        target="_blank"
                        rel="noreferrer"
                      >
                        LinkedIn
                      </a>
                    </Col>
                    <Col flex="none" className="sc-f-nf-itm">
                      <a
                        href="https://www.instagram.com/startcompanymn"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Instagram
                      </a>
                    </Col>
                  </Row>
                </Col>
                <Col xs={0} sm={0} md={20} lg={20} xl={0} xxl={0}>
                  <Row
                    gutter={[30, 10]}
                    justify="center"
                    className="sc-f-nf-itms"
                  >
                    <Col flex="none" className="sc-f-nf-itm">
                      <a
                        href="mailto:info@startco.net"
                        target="_blank"
                        rel="noreferrer"
                      >
                        info@startco.net
                      </a>
                    </Col>
                    <Col flex="none" className="sc-f-nf-itm">
                      <a
                        href="tel:+976-80002662"
                        target="_blank"
                        rel="noreferrer"
                      >
                        +976-80002662
                      </a>
                    </Col>
                    <Col flex="none" className="sc-f-nf-itm">
                      <a
                        href="https://www.facebook.com/startcompanymn"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Facebook
                      </a>
                    </Col>
                    <Col flex="none" className="sc-f-nf-itm">
                      <a
                        href="https://www.linkedin.com/company/startinternational"
                        target="_blank"
                        rel="noreferrer"
                      >
                        LinkedIn
                      </a>
                    </Col>
                    <Col flex="none" className="sc-f-nf-itm">
                      <a
                        href="https://www.instagram.com/startcompanymn"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Instagram
                      </a>
                    </Col>
                  </Row>
                </Col>
                <Col xs={20} sm={20} md={0}>
                  <Row gutter={[30, 10]} className="sc-f-nf-itms">
                    <Col span={24} className="sc-f-nf-itm">
                      <a
                        href="mailto:info@startco.net"
                        target="_blank"
                        rel="noreferrer"
                      >
                        info@startco.net
                      </a>
                    </Col>
                    <Col span={24} className="sc-f-nf-itm">
                      <a
                        href="tel:+976-80002662"
                        target="_blank"
                        rel="noreferrer"
                      >
                        +976-80002662
                      </a>
                    </Col>
                    <Col span={24} className="sc-f-nf-itm">
                      <a
                        href="https://www.facebook.com/startcompanymn"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Facebook
                      </a>
                    </Col>
                    <Col span={24} className="sc-f-nf-itm">
                      <a
                        href="https://www.linkedin.com/company/startinternational"
                        target="_blank"
                        rel="noreferrer"
                      >
                        LinkedIn
                      </a>
                    </Col>
                    <Col span={24} className="sc-f-nf-itm">
                      <a
                        href="https://www.instagram.com/startcompanymn"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Instagram
                      </a>
                    </Col>
                  </Row>
                </Col>
                <Col xs={20} sm={20} md={20} lg={20} xl={0} xxl={0}>
                  <Row justify="center" className="sc-f-copyright">
                    2022 START Venture Builder. All Rights Reserved.
                  </Row>
                </Col>
                <Col xs={0} sm={0} md={0} lg={0} xl={10} xxl={10}>
                  <Row justify="end" className="sc-f-copyright">
                    2022 START Venture Builder. All Rights Reserved.
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout.Footer>
  );
};
