import {
  Button,
  Col,
  Drawer,
  Image,
  Layout,
  Menu,
  Row,
  Select,
  Space,
} from "antd";
import { useEffect, useState } from "react";
import { matchPath } from "react-router";
import { Link, useLocation, useNavigate } from "react-router-dom";

import "./index.less";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { systemSelectors } from "store/selectors";
import { systemActions } from "store/actions";

const MenuObjects = [
  {
    pattern: "/",
    key: "/",
  },
  {
    pattern: "/venture-studio",
    key: "/venture-studio",
  },
  {
    pattern: "/portfolio",
    key: "/portfolio",
  },
  {
    pattern: "/about",
    key: "/about",
  },
  {
    pattern: "/training",
    key: "/training",
  },
  {
    pattern: "/event",
    key: "/event",
  },
];
const getMatchedMenuKey = ({ pathname }) =>
  MenuObjects.find(
    (menu) => matchPath(menu.pattern, pathname)?.pathnameBase === menu.key
  )?.key;

const HeaderC = ({ lang, changeLanguage }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const [current, setCurrent] = useState(getMatchedMenuKey(location) ?? "/");
  const [isDarkHeader, setIsDarkHeader] = useState(false);
  const [menuDrawerVisible, setMenuDrawerVisible] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [i18n, lang]);

  useEffect(() => {
    setIsDarkHeader(
      location.pathname === "/" || location.pathname === "/event"
    );
    setCurrent(getMatchedMenuKey(location) ?? "/");
  }, [location]);

  const onClick = (e) => {
    setCurrent(e.key);
    navigate(`${e.key}`);
    setMenuDrawerVisible(false);
  };

  return (
    <Layout.Header
      className={`sc-header-outer ${isDarkHeader && "sc-header-dark"}`}
    >
      <Row
        justify="center"
        align="middle"
        className={`sc-header-outer ${isDarkHeader && "sc-header-dark"}`}
      >
        <Col span={24} className="sc-pg-max-1440">
          <Row justify="center">
            <Col xs={22} sm={22} md={22} lg={22} xl={22} xxl={18}>
              <Row justify="center">
                <Col xs={20} sm={20} md={20} lg={20} xl={4} xxl={4}>
                  <div>
                    <Image
                      onClick={() => {
                        setCurrent("/");
                        navigate("/");
                      }}
                      className="sc-header-logo"
                      src={
                        isDarkHeader
                          ? require("assets/images/header-white-logo.svg")
                              .default
                          : require("assets/images/header-logo.svg").default
                      }
                      preview={false}
                    ></Image>
                  </div>
                </Col>
                <Col xs={0} sm={0} md={0} lg={0} xl={16} xxl={16}>
                  <Row justify="start">
                    <Col span={24}>
                      <Menu
                        mode="horizontal"
                        selectedKeys={[current]}
                        onClick={onClick}
                      >
                        <Menu.Item key="/venture-studio">
                          {t("menu.ventureStudio")}
                        </Menu.Item>
                        <Menu.Item key="/portfolio">
                          {t("menu.portfolioStartups")}
                        </Menu.Item>
                        <Menu.Item key="/about">{t("menu.about")}</Menu.Item>
                        <Menu.Item key="/training">
                          {t("menu.training")}
                        </Menu.Item>
                        <Menu.Item key="/event">{t("menu.event")}</Menu.Item>
                      </Menu>
                    </Col>
                  </Row>
                </Col>
                <Col xs={0} sm={0} md={0} lg={0} xl={4} xxl={4}>
                  <Space size={24}>
                    <Select
                      size="small"
                      className="sc-header-lng-slctr"
                      value={lang}
                      onChange={(value) => changeLanguage({ lang: value })}
                    >
                      <Select.Option value="mn">MN</Select.Option>
                      <Select.Option value="en">EN</Select.Option>
                    </Select>
                    <Button
                      className="contact-btn"
                      type="primary"
                      onClick={() => navigate("/contact-us")}
                    >
                      {t("menu.contact")}
                    </Button>
                  </Space>
                </Col>
                <Col xs={4} sm={4} md={4} lg={4} xl={0} xxl={0}>
                  <Row
                    justify="center"
                    align="middle"
                    className="sc-header-short-menu-outer"
                  >
                    <Image
                      src={require("../../../assets/images/header-short-menu-icon.png")}
                      preview={false}
                      className="sc-header-short-menu"
                      onClick={() => setMenuDrawerVisible(!menuDrawerVisible)}
                    />
                  </Row>
                </Col>
                <Drawer
                  placement="right"
                  closable={false}
                  visible={menuDrawerVisible}
                  onClose={() => setMenuDrawerVisible(false)}
                  width="100%"
                  className={`sc-header-menu-drawer ${
                    isDarkHeader && "sc-header-menu-drawer-dark"
                  }`}
                >
                  <Row justify="center">
                    <Col xs={22} sm={22} md={22} lg={22} xl={20}>
                      <Row
                        justify="center"
                        align="middle"
                        className={`sc-header-outer ${
                          isDarkHeader && "sc-header-dark"
                        }`}
                      >
                        <Col span={20}>
                          <Row justify="start" align="middle">
                            <Link to="/">
                              <Image
                                className="sc-header-logo"
                                src={require("assets/images/header-white-logo.png")}
                                preview={false}
                              />
                            </Link>
                          </Row>
                        </Col>
                        <Col span={4}>
                          <Row
                            justify="center"
                            align="middle"
                            className="app-header-short-menu-outer"
                          >
                            <Image
                              src={require("../../../assets/images/header-short-close-icon.png")}
                              className="app-header-short-menu"
                              onClick={() =>
                                setMenuDrawerVisible(!menuDrawerVisible)
                              }
                              preview={false}
                            />
                          </Row>
                        </Col>
                      </Row>
                      <Row
                        justify="start"
                        align="top"
                        style={{ height: "100%" }}
                      >
                        <Menu
                          theme="dark"
                          mode="vertical"
                          selectedKeys={[current]}
                          onClick={onClick}
                        >
                          <Menu.Item key="venture-studio">
                            {t("menu.ventureStudio")}
                          </Menu.Item>
                          <Menu.Item key="portfolio">
                            {t("menu.portfolioStartups")}
                          </Menu.Item>
                          <Menu.Item key="about">{t("menu.about")}</Menu.Item>
                          <Menu.Item key="training">
                            {t("menu.training")}
                          </Menu.Item>
                          <Menu.Item key="event">{t("menu.event")}</Menu.Item>
                        </Menu>
                        <Col span={24}>
                          <Row
                            align="middle"
                            style={{
                              height: 40,
                              paddingRight: 16,
                              paddingLeft: 16,
                            }}
                          >
                            <Select
                              size="small"
                              className="sc-header-lng-slctr"
                              value={lang}
                              onChange={(value) =>
                                changeLanguage({ lang: value })
                              }
                            >
                              <Select.Option value="mn">MN</Select.Option>
                              <Select.Option value="en">EN</Select.Option>
                            </Select>
                          </Row>
                          <Row
                            align="middle"
                            style={{
                              height: 40,
                              paddingRight: 16,
                              paddingLeft: 16,
                            }}
                          >
                            <Button
                              className="contact-btn"
                              type="primary"
                              onClick={() => navigate("/contact-us")}
                            >
                              {t("menu.contact")}
                            </Button>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Drawer>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout.Header>
  );
};

const mapStateToProps = (state) => ({
  lang: systemSelectors.getLang(state),
});

export const mapDispatchToProps = (dispatch) => ({
  changeLanguage(values) {
    dispatch(systemActions.changeLanguage(values));
  },
});

export const Header = connect(mapStateToProps, mapDispatchToProps)(HeaderC);
