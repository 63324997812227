const mn = {
  menu: {
    home: "Нүүр",
    ventureStudio: "Венчур студио",
    portfolioStartups: "Портфолио стартапууд",
    about: "Бидний тухай",
    training: "Сургалт зөвлөгөө",
    event: "Арга хэмжээ",
    contact: "Холбогдох",
    contactUs: "Бидэнтэй холбогдох",
  },
  contactForm: {
    contactTitle: "Бидэнтэй холбогдох",
    trainingTitle: "Сургалт зөвлөгөө",
    eventTitle: "Арга хэмжээ",
    description:
      "Доорх маягтыг бөглөж бидэнд мессеж илгээнэ үү.",
    lastname: {
      label: "Овог",
      required: "Овог оруулна уу",
    },
    firstname: {
      label: "Нэр",
      required: "Нэр оруулна уу",
    },
    email: {
      label: "Имэйл",
      required: "Имэйл оруулна уу",
      type: "Зөвхөн имэйл оруулна уу",
    },
    message: {
      label: "Мессеж",
      required: "Мессеж оруулна уу",
    },
    submit: "Оруулах",
    success: "Танд бид тун удахгүй хариу илгээх болно.",
    error: "Алдаа гарлаа. Та дахин оролдоно уу.",
  },
  founderItem: {
    title: "Хэрэглээнд нэвтэрсэн он: {{year}}: {{sector}}",
  },
  nextPage: "Дараагийн хуудас",
  getTraining: "Сургалт авах",
  getEvent: "Бидэнтэй холбогдох",
  getPortfolio: "Венчур Студио-н тухай мэдээлэл авах",
  memberTypes: {
    main: "Үндсэн баг",
    boardOfDirector: "ТУЗ-ийн гишүүд",
    advisor: "Зөвлөхүүд",
  },
  ecoSystemMap: {
    table: {
      startupName: "Стартапын нэр",
      sector: "Салбар",
    },
  },
  footer: {
    mongolianStartupEcosystemMap: "Монголын стартап\nэкосистемийн зураглал",
  },
  errorMessages: {
    system: {
      "Internal Server Error": "Серверт алдаа гарлаа.",
      "Network Failure": "Сүлжээнд алдаа гарлаа.",
      "Network Error": "Сүлжээнд алдаа гарлаа.",
    },
    auth: {
      "Email or password does NOT match":
        "Таны имэйл хаяг эсвэл нууц үг буруу байна.",
    },
  },
};

export default mn;
