import { Row, Col, Image, Table } from "antd";
import { ENDPOINT } from "constants";
import { t } from "i18next";
import { useEffect } from "react";
import { connect } from "react-redux";
import { systemActions } from "store/actions";
import { systemSelectors } from "store/selectors";

import "./index.less";

const Page = ({ lang, ecoSystemMap, getEcoSystemMap }) => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getEcoSystemMap();
  }, [getEcoSystemMap]);

  return (
    <Row>
      <Col span={24}>
        <Row align="bottom" justify="center" className="sc-ecsstm-h-o">
          <Col span={24} className="sc-ecsstm-h">
            <Row justify="center">
              <Col
                xs={22}
                sm={22}
                md={20}
                lg={20}
                xl={20}
                xxl={16}
                className="sc-ecsstm-h-mp"
              >
                {ecoSystemMap.image && (
                  <Image
                    preview={false}
                    src={`${ENDPOINT}${ecoSystemMap.image}`}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="center" className="sc-ecsstm-ovrw-o">
          <Col
            xs={22}
            sm={22}
            md={20}
            lg={20}
            xl={20}
            xxl={16}
            className="sc-ecsstm-ovrw"
          >
            {ecoSystemMap[`${lang}Content`]}
          </Col>
        </Row>
        <Row justify="center" className="sc-ecsstm-strtps-o">
          <Col xs={22} sm={22} md={20} lg={20} xl={20} xxl={16}>
            <Table
              pagination={false}
              columns={[
                {
                  title: "№",
                  responsive: ["md"],
                  render: (v, r, index) => `${index + 1}`,
                },
                {
                  title: t("ecoSystemMap.table.startupName"),
                  dataIndex: "name",
                },
                { title: t("ecoSystemMap.table.sector"), dataIndex: "sector" },
              ]}
              dataSource={ecoSystemMap.items}
            ></Table>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  lang: systemSelectors.getLang(state),
  ecoSystemMap: systemSelectors.getEcoSystemMap(state),
});

const mapDispatchToProps = (dispatch) => ({
  getEcoSystemMap() {
    dispatch(systemActions.getEcoSystemMap());
  },
});

export const EcoSystem = connect(mapStateToProps, mapDispatchToProps)(Page);
