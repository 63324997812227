import { NAME } from "./constants";

export const INIT = `@@${NAME}/INIT`;
export const INIT_SUCCESS = `@@${NAME}/INIT_SUCCESS`;
export const INIT_FAILED = `@@${NAME}/INIT_FAILED`;

export const CHANGE_LANGUAGE = `@@${NAME}/CHANGE_LANGUAGE`;
export const CHANGE_LANGUAGE_SUCCESS = `@@${NAME}/CHANGE_LANGUAGE_SUCCESS`;
export const CHANGE_LANGUAGE_FAILED = `@@${NAME}/CHANGE_LANGUAGE_FAILED`;

export const GET_HOME = `@@${NAME}/GET_HOME`;
export const GET_HOME_SUCCESS = `@@${NAME}/GET_HOME_SUCCESS`;
export const GET_HOME_FAILED = `@@${NAME}/GET_HOME_FAILED`;

export const GET_VENTURE_STUDIO = `@@${NAME}/GET_VENTURE_STUDIO`;
export const GET_VENTURE_STUDIO_SUCCESS = `@@${NAME}/GET_VENTURE_STUDIO_SUCCESS`;
export const GET_VENTURE_STUDIO_FAILED = `@@${NAME}/GET_VENTURE_STUDIO_FAILED`;

export const GET_PORTFOLIO = `@@${NAME}/GET_PORTFOLIO`;
export const GET_PORTFOLIO_SUCCESS = `@@${NAME}/GET_PORTFOLIO_SUCCESS`;
export const GET_PORTFOLIO_FAILED = `@@${NAME}/GET_PORTFOLIO_FAILED`;

export const GET_EVENT = `@@${NAME}/GET_EVENT`;
export const GET_EVENT_SUCCESS = `@@${NAME}/GET_EVENT_SUCCESS`;
export const GET_EVENT_FAILED = `@@${NAME}/GET_EVENT_FAILED`;

export const GET_TRAINING = `@@${NAME}/GET_TRAINING`;
export const GET_TRAINING_SUCCESS = `@@${NAME}/GET_TRAINING_SUCCESS`;
export const GET_TRAINING_FAILED = `@@${NAME}/GET_TRAINING_FAILED`;

export const GET_ABOUT = `@@${NAME}/GET_ABOUT`;
export const GET_ABOUT_SUCCESS = `@@${NAME}/GET_ABOUT_SUCCESS`;
export const GET_ABOUT_FAILED = `@@${NAME}/GET_ABOUT_FAILED`;

export const GET_ECOSYSTEM_MAP = `@@${NAME}/GET_ECOSYSTEM_MAP`;
export const GET_ECOSYSTEM_MAP_SUCCESS = `@@${NAME}/GET_ECOSYSTEM_MAP_SUCCESS`;
export const GET_ECOSYSTEM_MAP_FAILED = `@@${NAME}/GET_ECOSYSTEM_MAP_FAILED`;

export const CONTACT_US = `@@${NAME}/CONTACT_US`;
export const CONTACT_US_SUCCESS = `@@${NAME}/CONTACT_US_SUCCESS`;
export const CONTACT_US_FAILED = `@@${NAME}/CONTACT_US_FAILED`;
