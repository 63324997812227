import { get, post } from "./api.helper";

//
// User
export const postJoinUsList = () => post("/join_us");
export const getGetFounderReviews = () => get("founder-review");
export const getGetVentureSteps = () => get("venture-step");
export const getGetProductForStartups = () => get("product-for-startup");
export const getGetFounders = () => get("founder");
export const getGetPortfolioStartups = () => get("portfolio");
export const getGetFounderComments = () => get("founder-comments");
export const getGetHome = () => get("home");
export const getGetVentureStudio = () => get("venture-studio");
export const getGetPortfolio = () => get("portfolio");
export const getGetEvent = () => get("event");
export const getGetTraining = () => get("training");
export const getGetAbout = () => get("about");
export const getGetEcoSystemMap = () => get("start-up-map");
export const postContactUs = (data) => post("contact", data);
