import { useEffect, useLayoutEffect, useState } from "react";
import { Col, Image, Row } from "antd";
import { FoundItem } from "components";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { systemSelectors } from "store/selectors";
import { systemActions } from "store/actions";
import { ENDPOINT } from "constants";

import "./index.less";

const Page = ({ lang, home, getHome }) => {
  const { t } = useTranslation();
  const [slickShowItems, setSlickShowItems] = useState(1);
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getHome();
  }, [getHome]);

  const updateWindowConfig = () => {
    if (window.innerWidth <= 1200) {
      setSlickShowItems(1);
    } else if (window.innerWidth <= 1440) {
      setSlickShowItems(2);
    } else {
      // setSlickShowItems(3);
      setSlickShowItems(2);
    }
  };

  useLayoutEffect(() => {
    updateWindowConfig();
    window.addEventListener("resize", updateWindowConfig);
    return () => window.removeEventListener("resize", updateWindowConfig);
  }, []);

  const getHomeHeaderStyle = () => {
    return home["headerImage"]
      ? { backgroundImage: `url(${ENDPOINT}${home["headerImage"]})` }
      : {};
  };

  return (
    <Row>
      <Col span={24}>
        <Row className="sc-hb-o" style={getHomeHeaderStyle()}>
          <Col span={24} className="sc-pg-max-1440">
            <Row justify="center">
              <Col span={22} className="sc-hb-h">
                <Row justify="center" data-aos="fade-up">
                  <span className="sc-hb-h-st">{`${
                    home[`${lang}SubTitle`] ?? ""
                  }`}</span>
                </Row>
                <Row justify="center" data-aos="fade-up">
                  <span
                    className="sc-hb-h-t"
                    dangerouslySetInnerHTML={{
                      __html: `${home[`${lang}Title`] ?? ""}`,
                    }}
                  ></span>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24} className="sc-pg-max-1440">
            <Row justify="center">
              <Col
                xs={20}
                sm={20}
                md={16}
                lg={22}
                xl={22}
                xxl={18}
                className="sc-hb-scs"
              >
                <Row align="center" gutter={24} justify="center">
                  {(home.headerMenus ?? []).map((item) => (
                    <Col
                      xs={24}
                      sm={24}
                      md={18}
                      lg={8}
                      xxl={8}
                      className="sc-hb-sc"
                    >
                      <Row className="sc-hb-sc-i">
                        <Col span={24}>
                          <Row className="sc-hb-sc-t">
                            {item[`${lang}Title`] ?? ""}
                          </Row>
                          <Row className="sc-hb-sc-c">
                            {item[`${lang}Content`] ?? ""}
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="center" className="sc-hvs">
          <Col span={24} className="sc-pg-max-1440">
            <Row justify="center">
              <Col
                xs={20}
                sm={20}
                md={21}
                lg={19}
                xl={19}
                xxl={15}
                className="sc-hvs-h"
              >
                <Row data-aos="fade-up" className="sc-hvs-h-st">
                  {home[`${lang}FounderReviewSubTitle`] ?? ""}
                </Row>
                <Row data-aos="fade-up" className="sc-hvs-h-t">
                  {home[`${lang}FounderReviewTitle`] ?? ""}
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24} className="sc-pg-max-1440">
            <Row justify="center">
              <Col xs={22} sm={22} md={20} lg={21} xl={21} xxl={16}>
                {/* Items */}
                <Slider
                  className="sc-slider"
                  slidesToShow={slickShowItems}
                  swipeToSlide={1}
                  focusOnSelect
                  variableWidth
                  arrows
                  infinite={false}
                  prevArrow={
                    <button>
                      <Image
                        src={require("../../assets/images/slider-prev-arrow.png")}
                        preview={false}
                      />
                    </button>
                  }
                  nextArrow={
                    <button>
                      <Image
                        src={require("../../assets/images/slider-next-arrow.png")}
                        preview={false}
                      />
                    </button>
                  }
                >
                  {(home?.founderReviews ?? []).map((item, index) => (
                    <FoundItem
                      key={`fi-${index}`}
                      image={`${ENDPOINT}${item.image}`}
                      content={item[`${lang}Content`] ?? ""}
                      author={item[`${lang}Author`] ?? ""}
                      authorPosition={item[`${lang}AuthorPosition`] ?? ""}
                    />
                  ))}
                </Slider>
              </Col>
            </Row>
          </Col>
          <Col span={24} className="sc-pg-max-1440">
            <Row justify="center">
              <Col
                xs={20}
                sm={20}
                md={21}
                lg={19}
                xl={19}
                xxl={15}
                className="sc-hvs-f"
              >
                <Row className="sc-hvs-f-st">{t("nextPage")}</Row>
                <Link to="/venture-studio">
                  <Row className="sc-hvs-f-t">
                    <span>{t("menu.ventureStudio")}</span>
                    <Image
                      preview={false}
                      src={require("../../assets/images/left-arrow-red.png")}
                      className="sc-hvs-f-t-arrow"
                    />
                  </Row>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  lang: systemSelectors.getLang(state),
  loading: systemSelectors.getHomeLoading(state),
  home: systemSelectors.getHome(state),
});

const mapDispatchToProps = (dispatch) => ({
  getHome() {
    dispatch(systemActions.getHome());
  },
});

export const Home = connect(mapStateToProps, mapDispatchToProps)(Page);
