import { Navigate, useRoutes } from "react-router-dom";

import {
  About,
  Home,
  Portfolio,
  Training,
  VentureStudio,
  Event,
  EcoSystem,
  ContactUs,
  ContactTraining,
  ContactEvent,
} from "pages";

import { MainLayout } from "../main-layout";

const routesObjects = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { index: true, element: <Home /> },
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/venture-studio",
        element: <VentureStudio />,
      },
      {
        path: "/portfolio",
        element: <Portfolio />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/mongolian-startup-eco-system-map",
        element: <EcoSystem />,
      },
      {
        path: "/contact-us",
        element: <ContactUs />,
      },
      {
        path: "/contact-training",
        element: <ContactTraining />,
      },
      {
        path: "/contact-event",
        element: <ContactEvent />,
      },
      {
        path: "/training",
        element: <Training />,
      },
      {
        path: "/event",
        element: <Event />,
      },
      {
        path: "*",
        element: <Navigate replace to="/" />,
      },
    ],
  },
];

export const AppRoutes = () => {
  const routes = useRoutes(routesObjects);
  return <>{routes}</>;
};
