import { DEFAULT_LANG } from "constants";
import {
  getGetAbout,
  getGetEcoSystemMap,
  getGetEvent,
  getGetHome,
  getGetPortfolio,
  getGetTraining,
  getGetVentureStudio,
  postContactUs,
} from "helpers/backend.helper";
import { takeLatest, put, call } from "redux-saga/effects";

import * as actions from "./actions";

export function* init() {
  try {
    const lang = localStorage.getItem("lang") ?? DEFAULT_LANG ?? "mn";
    yield put(actions.initSuccess({ lang }));
  } catch (error) {
    yield put(actions.initFailed({ lang: DEFAULT_LANG ?? "mn" }));
  }
}

export function* watchInit() {
  yield takeLatest(actions.init, init);
}

export function* changeLanguage(action) {
  try {
    const { lang } = action.payload;
    localStorage.setItem("lang", lang);
    yield put(actions.changeLanguageSuccess({ lang }));
  } catch (error) {
    yield put(actions.changeLanguageFailed({ lang: DEFAULT_LANG ?? "mn" }));
  }
}

export function* watchChangeLanguage() {
  yield takeLatest(actions.changeLanguage, changeLanguage);
}

export function* getHome(action) {
  try {
    const home = yield call(getGetHome);
    yield put(actions.getHomeSuccess({ home }));
  } catch (error) {
    yield put(actions.getHomeFailed(error));
  }
}

export function* watchGetHome() {
  yield takeLatest(actions.getHome, getHome);
}

export function* getVentureStudio(action) {
  try {
    const ventureStudio = yield call(getGetVentureStudio);
    yield put(actions.getVentureStudioSuccess({ ventureStudio }));
  } catch (error) {
    yield put(actions.getVentureStudioFailed(error));
  }
}

export function* watchGetVentureStudio() {
  yield takeLatest(actions.getVentureStudio, getVentureStudio);
}

export function* getPortfolio() {
  try {
    const portfolio = yield call(getGetPortfolio);
    yield put(actions.getPortfolioSuccess({ portfolio }));
  } catch (error) {
    yield put(actions.getPortfolioFailed(error));
  }
}

export function* watchGetPortfolio() {
  yield takeLatest(actions.getPortfolio, getPortfolio);
}

export function* getEvent() {
  try {
    const event = yield call(getGetEvent);
    yield put(actions.getEventSuccess({ event }));
  } catch (error) {
    yield put(actions.getEventFailed(error));
  }
}

export function* watchGetEvent() {
  yield takeLatest(actions.getEvent, getEvent);
}

export function* getTraining() {
  try {
    const training = yield call(getGetTraining);
    yield put(actions.getTrainingSuccess({ training }));
  } catch (error) {
    yield put(actions.getTrainingFailed(error));
  }
}

export function* watchGetTraining() {
  yield takeLatest(actions.getTraining, getTraining);
}

export function* getAbout() {
  try {
    const about = yield call(getGetAbout);
    yield put(actions.getAboutSuccess({ about }));
  } catch (error) {
    yield put(actions.getAboutFailed(error));
  }
}

export function* watchGetAbout() {
  yield takeLatest(actions.getAbout, getAbout);
}

export function* getEcoSystemMap() {
  try {
    const ecoSystemMap = yield call(getGetEcoSystemMap);
    yield put(actions.getEcoSystemMapSuccess({ ecoSystemMap }));
  } catch (error) {
    yield put(actions.getEcoSystemMapFailed(error));
  }
}

export function* watchGetEcoSystemMap() {
  yield takeLatest(actions.getEcoSystemMap, getEcoSystemMap);
}

export function* contactUs(action) {
  try {
    yield call(postContactUs, action.payload);
    yield put(actions.contactUsSuccess());
  } catch (error) {
    yield put(actions.contactUsFailed(error));
  }
}

export function* watchContactUs() {
  yield takeLatest(actions.contactUs, contactUs);
}

const userSagas = [
  watchInit,
  watchChangeLanguage,
  watchGetHome,
  watchGetVentureStudio,
  watchGetPortfolio,
  watchGetEvent,
  watchGetTraining,
  watchGetAbout,
  watchGetEcoSystemMap,
  watchContactUs,
];
export default userSagas;
