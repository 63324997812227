import { createSelector } from 'reselect'
import * as constants from './constants'

export const getState = (state) => state[constants.NAME]

// Loading
export const getJoinUsLoading = createSelector(
  getState,
  (state) => state.loader[constants.JOIN_US]
)

// Response
export const getJoinUsResponse = createSelector(
  getState,
  (state) => state.responses[constants.JOIN_US]
)

// Error
export const getJoinUsError = createSelector(
  getState,
  (state) => state.errors[constants.JOIN_US]
)
