const en = {
  menu: {
    home: "Home",
    ventureStudio: "Venture studio",
    portfolioStartups: "Portfolio startups",
    about: "About us",
    training: "Trainings and Workshop",
    event: "Events",
    contact: "Contact",
    contactUs: "Contact Us",
  },
  contactForm: {
    contactTitle: "Contact us",
    trainingTitle: "Training",
    eventTitle: "Event",
    description:
      "Send us a message by filling out the form below.",
    lastname: {
      label: "Lastname",
      required: "Please enter your lastname",
    },
    firstname: {
      label: "Firstname",
      required: "Please enter your firstname",
    },
    email: {
      label: "Email",
      required: "Please enter your email",
      type: "Please enter only your email",
    },
    message: {
      label: "Message",
      required: "Please enter a message",
    },
    submit: "Enter",
    success: "Thank you! We will contact you as soon as possible.",
    error: "Error. Please try again.",
  },
  founderItem: {
    title: "Released date: {{year}}: {{sector}}",
  },
  nextPage: "Next page",
  getTraining: "Get training",
  getEvent: "Get in touch",
  getPortfolio: "More about Venture Studio",
  memberTypes: {
    main: "Main",
    boardOfDirector: "Board of director",
    advisor: "Advisor",
  },
  ecoSystemMap: {
    table: {
      startupName: "Startup name",
      sector: "Sector",
    },
  },
  footer: {
    mongolianStartupEcosystemMap: "Mongolian Startup\nEcosystem map",
  },
  errorMessages: {
    system: {
      "Internal Server Error": "Серверт алдаа гарлаа.",
      "Network Failure": "Сүлжээнд алдаа гарлаа.",
      "Network Error": "Сүлжээнд алдаа гарлаа.",
    },
    auth: {
      "Email or password does NOT match":
        "Таны имэйл хаяг эсвэл нууц үг буруу байна.",
    },
  },
};

export default en;
