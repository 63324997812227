import { Row, Col, Form, Input, Button, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useCallback, useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { systemActions } from "store/actions";
import { systemSelectors } from "store/selectors";
import { GOOGLE_RECAPTCHA_KEY } from "constants";

import "../index.less";

const Page = ({ lang, loading, response, error, contactUs }) => {
  const { t } = useTranslation();
  const [submit, setSubmit] = useState(false);
  const [form] = useForm();
  const [token, setToken] = useState();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (submit) {
      if (response) {
        message.success(t("contactForm.success"));
        setRefreshReCaptcha(true);
        setToken();
        form.resetFields();
      } else if (error) {
        message.error(t("contactForm.error"));
        setRefreshReCaptcha(true);
        setToken();
      }
    }
  }, [submit, response, error, form]);

  const onVerify = useCallback(
    (token) => {
      setToken(token);
    },
    [setToken]
  );

  const onFinish = (values) => {
    if (token) {
      setSubmit(true);
      contactUs({
        type: "contact",
        lang,
        token,
        ...values,
      });
    }
  };

  return (
    <Row>
      <Col span={24} className="sc-cntc">
        <GoogleReCaptchaProvider
          reCaptchaKey={`${GOOGLE_RECAPTCHA_KEY}`}
          language={lang}
          scriptProps={{ async: true, defer: true, appendTo: "body" }}
        >
          <Row align="bottom" justify="center" className="sc-cntc-h-o">
            <Col
              xs={20}
              sm={20}
              md={18}
              lg={12}
              xl={12}
              xxl={8}
              className="sc-cntc-h"
            >
              <Row className="sc-cntc-h-t">{t("contactForm.contactTitle")}</Row>
              <Row className="sc-cntc-h-st">{t("contactForm.description")}</Row>
            </Col>
          </Row>
          <Row justify="center" className="sc-cntc-frm">
            <Col xs={20} sm={20} md={18} lg={12} xl={12} xxl={8}>
              <Form
                form={form}
                name="contact-us"
                layout="vertical"
                onFinish={onFinish}
              >
                <Form.Item
                  name="lastname"
                  label={t("contactForm.lastname.label")}
                  rules={[
                    {
                      required: true,
                      message: t("contactForm.lastname.required"),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="firstname"
                  label={t("contactForm.firstname.label")}
                  rules={[
                    {
                      required: true,
                      message: t("contactForm.firstname.required"),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="email"
                  label={t("contactForm.email.label")}
                  rules={[
                    {
                      required: true,
                      message: t("contactForm.email.required"),
                    },
                    { type: "email", message: t("contactForm.email.type") },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="message"
                  label={t("contactForm.message.label")}
                  rules={[
                    {
                      required: true,
                      message: t("contactForm.message.required"),
                    },
                  ]}
                >
                  <Input.TextArea />
                </Form.Item>
                <Form.Item>
                  <GoogleReCaptcha
                    onVerify={onVerify}
                    refreshReCaptcha={refreshReCaptcha}
                  />
                </Form.Item>
                <Form.Item>
                  <Button loading={loading} htmlType="submit" type="primary">
                    {t("contactForm.submit")}
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </GoogleReCaptchaProvider>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  lang: systemSelectors.getLang(state),
  loading: systemSelectors.getContactUsLoading(state),
  response: systemSelectors.getContactUsResponse(state),
  error: systemSelectors.getContactUsError(state),
});

const mapDispatchToProps = (dispatch) => ({
  contactUs(values) {
    dispatch(systemActions.contactUs(values));
  },
});

export const ContactUs = connect(mapStateToProps, mapDispatchToProps)(Page);
